import { ActivatedRoute, Router, UrlSegment } from '@angular/router'
import {
    BehaviorSubject,
    Observable,
    Subject,
    combineLatest,
    map, takeUntil,
} from 'rxjs'
import { Business } from '@app/domain/Business'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { ContextService } from '@services/context.service'
import { Helper } from '@app/helpers/utilities/helper'
import { MenuComponent } from '@app/root/components/menu/menu.component'
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap'
import { Organisation } from '@app/domain/Organisation'
import { RequestedBookingsCountPublisher } from '@app/domain/RequestedBookingsCountPublisher'
import { ToolbarService } from '@services/toolbar.service'
import { Venue } from '@app/domain/Venue'

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
})
export class ToolbarComponent implements OnInit, OnDestroy {

    _showToolbar = new BehaviorSubject<boolean>(true)
    showToolbar$ = this._showToolbar.asObservable()
    showMenu$!: Observable<boolean>
    business$ = this.contextService.business$
    venue$ = this.contextService.venue$
    breadcrumbs$!: Observable<{ label: string, url: string }[]>
    activeRoute = new BehaviorSubject<string>('')
    activeRoute$ = this.activeRoute.asObservable()
    private onDestroy$ = new Subject<void>()

    constructor(
        private contextService: ContextService,
        public offCanvasService: NgbOffcanvas,
        public requestedBookingsCountPublisher: RequestedBookingsCountPublisher,
        private router: Router,
        private route: ActivatedRoute,
        private toolbarService: ToolbarService
    ) {
        this.bindShowingMenuToToolbarPlacement()
        this.bindToolbarMenuRequestedToOpeningMenu()
        this.bindRouteToMenu()
        this.bindRouteToShowingToolbar()
    }

    ngOnInit() {
        this.breadcrumbs$ = combineLatest([
            this.contextService.organisation$,
            this.contextService.business$,
            this.contextService.venue$,
            this.router.events,
        ])
            .pipe(
                map(([organisation, business, venue, _]) => {
                    return this.buildBreadcrumbs(organisation, business, venue, this.route)
                })
            )
    }

    ngOnDestroy() {
        this.onDestroy$.next()
        this.onDestroy$.complete()
    }

    openOffCanvasMenu() {
        const ref = this.offCanvasService.open(MenuComponent, {
            panelClass: 'rounded-end flex-fill',
        })
        const menu = ref.componentInstance as MenuComponent
        this.activeRoute$
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(route => {
                menu.activeRoute = route
            })
    }

    private buildBreadcrumbs(
        organisation: Organisation,
        business: Business,
        venue: Venue,
        route: ActivatedRoute
    ): { label: string, url: string }[] {
        const params = route.snapshot.params
        const items = [
            {
                key: 'activity',
                type: 'urlSegment',
                resolveLabel: () => 'Activity',
            },
            {
                // inbox
                key: 'inbox',
                type: 'urlSegment',
                resolveLabel: () => 'Inbox',
            },
            {
                key: 'reports',
                type: 'urlSegment',
                resolveLabel: () => 'Reports',
            },
            {
                key: 'bookings',
                type: 'urlSegment',
                resolveLabel: () => 'Bookings',
            },
            {
                key: 'bookings-feedback',
                type: 'urlSegment',
                resolveLabel: () => 'Bookings Feedback',
            },
            {
                key: 'bookings-per-hour',
                type: 'urlSegment',
                resolveLabel: () => 'Bookings per Hour',
            },
            {
                key: 'deposits',
                type: 'urlSegment',
                resolveLabel: () => 'Deposits',
            },
            {
                key: 'customer-list-export',
                type: 'urlSegment',
                resolveLabel: () => 'Customer List Export',
            },
            {
                key: 'uncompleted-booking-requests',
                type: 'urlSegment',
                resolveLabel: () => 'Uncompleted Booking Requests',
            },
            {
                key: 'management',
                type: 'urlSegment',
                resolveLabel: () => 'Management',
            },
            {
                key: 'profile',
                type: 'urlSegment',
                resolveLabel: () => 'Account',
            },
            {
                key: 'business',
                type: 'urlSegment',
                resolveLabel: () => business.displayName,
            },
            {
                'key': 'schedules',
                type: 'urlSegment',
                resolveLabel: () => 'Schedules',
            },
            {
                key: 'scheduleId',
                type: 'param',
                resolveLabel: (scheduleId: string) => {
                    const schedule = organisation.scheduleWithId(scheduleId)
                    return schedule ? schedule.displayName : 'Schedule'
                },
            },
            {
                key: 'serviceId',
                type: 'param',
                resolveLabel: (serviceId: string) => {
                    const scheduleId = params['scheduleId']
                    const schedule = organisation.scheduleWithId(scheduleId)
                    return schedule?.serviceWithId(serviceId)?.displayName ?? 'Service'
                },
            },
            {
                key: 'ruleId',
                type: 'param',
                resolveLabel: (ruleId: string) => {
                    const scheduleId = params['scheduleId']
                    const serviceId = params['serviceId']
                    const schedule = organisation.scheduleWithId(scheduleId)
                    const service = schedule?.serviceWithId(serviceId)
                    const rule = service?.ruleWithId(ruleId)
                    return rule?.period.open ? 'Open Period' : 'Closed Period'
                },
            },
            {
                key: 'venues',
                type: 'urlSegment',
                resolveLabel: () => 'Venues',
            },
            {
                key: 'venueId',
                type: 'param',
                resolveLabel: (venueId: string) => {
                    const venue = business.getVenue(venueId)
                    return venue ? venue.displayName : 'Venue'
                },
            },
            {
                key: 'reasons',
                type: 'urlSegment',
                resolveLabel: () => 'Reasons',
            },
            {
                key: 'reasonId',
                type: 'param',
                resolveLabel: (reasonId: string) => {
                    const venueId = params['venueId']
                    const venue = business.getVenue(venueId)
                    return venue?.reasonWithId(reasonId)?.displayName ?? 'Reason'
                },
            },
            {
                key: 'questions',
                type: 'urlSegment',
                resolveLabel: () => 'Questions',
            },
            {
                key: 'questionId',
                type: 'param',
                resolveLabel: (questionId: string) => {
                    const venueId = params['venueId']
                    const venue = business.getVenue(venueId)
                    return venue?.questionWithId(questionId)?.merchantDisplayText ?? 'Question'
                },
            },
            {
                key: 'payments',
                type: 'urlSegment',
                resolveLabel: () => 'Payments',
            },
            {
                key: 'events',
                type: 'urlSegment',
                resolveLabel: () => 'Events',
            },
            {
                key: 'eventId',
                type: 'param',
                resolveLabel: (eventId: string) => {
                    const venueId = params['venueId']
                    const venue = business.getVenue(venueId)
                    return venue?.eventWithId(eventId)?.displayName ?? 'Event'
                },
            },
            {
                key: 'schedule',
                type: 'urlSegment',
                resolveLabel: () => 'Schedule & Repetition',
            },
            {
                key: 'limits',
                type: 'urlSegment',
                resolveLabel: () => 'Limits',
            },
            {
                key: 'vouchers',
                type: 'urlSegment',
                resolveLabel: () => 'Vouchers',
            },
            {
                key: 'gifted',
                type: 'urlSegment',
                resolveLabel: () => 'Sold Vouchers',
            },
            {
                key: 'giftedVoucherId',
                type: 'param',
                resolveLabel: () => 'Voucher',
            },
            {
                key: 'voucherId',
                type: 'param',
                resolveLabel: () => 'Voucher',
            },
            {
                key: 'areas',
                type: 'urlSegment',
                resolveLabel: () => 'Areas',
            },
            {
                key: 'areaId',
                type: 'param',
                resolveLabel: (areaId: string) => {
                    return venue.areaWithId(areaId)?.displayName ?? 'Area'
                },
            },
            {
                key: 'floor-plan',
                type: 'urlSegment',
                resolveLabel: () => 'Floor Plan',
            },
            {
                key: 'booking',
                type: 'urlSegment',
                resolveLabel: () => 'Booking Channels',
            },
            {
                key: 'email',
                type: 'urlSegment',
                resolveLabel: () => 'Email Branding',
            },
            {
                key: 'integrations',
                type: 'urlSegment',
                resolveLabel: () => 'Integrations',
            },
            {
                key: 'email-octopus',
                type: 'urlSegment',
                resolveLabel: () => 'Email Octopus',
            },
            {
                key: 'reserve-with-google',
                type: 'urlSegment',
                resolveLabel: () => 'Reserve with Google',
            },
            {
                key: 'import',
                type: 'urlSegment',
                resolveLabel: () => 'Booking Import',
            },
        ]
        const urlSegments = route.snapshot.url
        const breadcrumbs = items.flatMap(item => {
            if (item.type === 'urlSegment') {
                return this.makePathComponentBreadcrumbsFromUrlSegments(item, urlSegments)
            } else if (item.type === 'param') {
                return this.makeQueryParamBreadcrumbsFromUrlSegments(item, params, urlSegments)
            }
            return null
        })
            .filter(breadcrumb => breadcrumb !== null) as { label: string, url: string }[]
        const childRoute = route.firstChild
        if (childRoute) {
            return breadcrumbs.concat(
                this.buildBreadcrumbs(organisation, business, venue, route.firstChild)
            )
        } else {
            return breadcrumbs
        }
    }

    private makePathComponentBreadcrumbsFromUrlSegments(
        item: { key: string, resolveLabel: (value: string) => string },
        urlSegments: UrlSegment[]
    ) {
        return urlSegments
            .filter(segment => segment.path === item.key)
            .map(segment => {
                const url = this.router.url
                    .split('/')
                    .slice(0, this.router.url.split('/').indexOf(segment.path) + 1)
                    .join('/')
                return {
                    label: item.resolveLabel(''),
                    url: url,
                }
            })
    }

    private makeQueryParamBreadcrumbsFromUrlSegments(
        item: { key: string, resolveLabel: (value: string) => string },
        params: { [key: string]: string },
        urlSegments: UrlSegment[]
    ) {
        const value = params[item.key]
        if (!value) {
            return null
        }
        return urlSegments
            .filter(segment => segment.path === value)
            .map(segment => {
                const url = this.router.url
                    .split('/')
                    .slice(0, this.router.url.split('/').indexOf(segment.path) + 1)
                    .join('/')
                return {
                    label: item.resolveLabel(value),
                    url: url,
                }
            })
    }

    private bindRouteToShowingToolbar() {
        this.router.events
            .pipe(
                takeUntil(this.onDestroy$),
                map(() => {
                    return this.routeShouldShowToolbar(this.route)
                })
            )
            .subscribe(showToolbar => {
                this._showToolbar.next(showToolbar)
            })
    }

    private routeShouldShowToolbar(
        route: ActivatedRoute
    ): boolean {
        if (route.snapshot.data['showToolbar'] === false) {
            return false
        } else if (route.firstChild) {
            return this.routeShouldShowToolbar(route.firstChild)
        }
        return true
    }

    private bindShowingMenuToToolbarPlacement() {
        this.showMenu$ = this.toolbarService.toolbarPlacement$
            .pipe(
                takeUntil(this.onDestroy$),
                map(placement => placement === 'top')
            )
    }

    private bindToolbarMenuRequestedToOpeningMenu() {
        this.toolbarService.openMenuRequested
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(() => {
                this.openOffCanvasMenu()
            })
    }

    private bindRouteToMenu() {
        this.router.events
            .pipe(
                map(() => Helper.relativePath(this.router.url))
            )
            .subscribe(url => this.activeRoute.next(url))
    }
}
