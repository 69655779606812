import { AppComponent } from './components/app/app.component'
import { AppRoutingModule } from './app-routing.module'
import { AuthCallbackComponent } from './components/auth-callback/auth-callback.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { BrowserModule } from '@angular/platform-browser'
import {
    ExpiredBannerComponent,
} from '@app/root/components/expired-banner/expired-banner.component'
import { FeedbackComponent } from './components/feedback/feedback.component'
import { LOCALE_ID, NgModule, isDevMode } from '@angular/core'
import { MenuComponent } from '@app/root/components/menu/menu.component'
import { NgOptimizedImage, registerLocaleData } from '@angular/common'
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { NgxPaginationModule } from 'ngx-pagination'
import { ServiceWorkerModule } from '@angular/service-worker'
import { SharedModule } from '@app/features/shared/shared.module'
import {
    SubscriptionBannerComponent,
} from '@app/root/components/subscription-banner/subscription-banner.component'
import { ToastsComponent } from './components/toast/toasts.component'
import { ToolbarComponent } from './components/toolbar/toolbar.component'
import { TrialBannerComponent } from './components/trial-banner/trial-banner.component'
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import gbLocale from '@angular/common/locales/en-GB'
import { QuillModule } from 'ngx-quill'

registerLocaleData(gbLocale)

@NgModule({
    bootstrap: [
        AppComponent,
    ],
    declarations: [
        AppComponent,
        AuthCallbackComponent,
        ExpiredBannerComponent,
        FeedbackComponent,
        MenuComponent,
        SubscriptionBannerComponent,
        ToastsComponent,
        ToolbarComponent,
        TrialBannerComponent,
    ],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        QuillModule.forRoot({
            modules: {
                toolbar: [
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    [{ indent: '-1' }, { indent: '+1' }],
                    [{ size: ['small', false, 'large', 'huge'] }],
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    ['clean'],
                ],
            },
        }),
        NgbDropdownModule,
        NgbModule,
        NgOptimizedImage,
        NgxPaginationModule,
        SharedModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
    ],
    providers: [
        {
            provide: LOCALE_ID,
            useValue: 'en-GB',
        },
        provideHttpClient(withInterceptorsFromDi()),
    ],
})
export class AppModule { }
